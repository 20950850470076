<div class="modal-header">
      <h1 class="modal-title" id="modal-title">Profil mušterije</h1>
      <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
</div>

<div class="modal-body">
    <table>
        <tr>
            <td style="text-align: end;">Ime:</td>
            <td><input [formControl]="name"></td>
        </tr>
        <tr>
            <td style="text-align: end;">Telefon:</td>
            <td><input [formControl]="telefon"></td>
        </tr>
        <tr>
            <td style="text-align: end;">Link:</td>
            <td><input type="text" value="https://www.locadelacabeza.nikolas-test.rs/client/{{musterija.personalLink}}" disabled=true></td>
        </tr>
    </table>
</div>



<div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary" (click)="kopirajLink()">Kopiraj Link</button>
      <button type="button" ngbAutofocus class="btn btn-success" (click)="sacuvaj()">Sačuvaj</button>
      <button type="button" class="btn btn-danger" (click)="izbrisi()">Izbriši</button>
</div>