import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Appointment } from 'src/app/model/appointment.model';
import { Customer } from 'src/app/model/customer.model';
import { AppointmentService } from 'src/app/service/appointment.service';
import { CustomerService } from 'src/app/service/customer.service';
import { NgbdModalAccept } from 'src/app/util/modal-accept';
import { NgbdModalConfirmTime } from 'src/app/util/modal-confirm-time';
import { NgbdModalReject } from 'src/app/util/modal-reject';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.css']
})
export class ClientComponent implements OnInit {
  prikazaniTermini=false;
  Code;
  IDCustomer;
  date=new Date();
  next10:Date[];
  termini:Array<Map<String,Boolean>>;
  nextAppoinments:Array<Appointment>;
  myAppointments=new Array<Appointment>();

  dani=["Nedelja","Ponedeljak","Utorak","Sreda","Četvrtak","Petak","Subota","Nedelja"]



  constructor(private appointmentService:AppointmentService,private customerService:CustomerService,private cdr: ChangeDetectorRef,private _modalService: NgbModal,private route: ActivatedRoute,private router:Router) { }

  ngOnInit(): void {
    this.route.params.subscribe( params => {
      this.Code=(params["id"])
      this.next10=new Array<Date>();
       this.customerService.GetByCode(this.Code).subscribe((customer: Array<Customer>)=>{
         if(customer.length==0)
         {
           this.router.navigate(["404"])
         }
      this.IDCustomer=customer[0].ID;
    });
      this.appointmentService.GetAllForByCodeOrderByDateDesc(this.Code).subscribe((appointments: Array<Appointment>)=>{
      this.myAppointments=appointments;
      this.cdr.detectChanges();
    });
    });


    this.changeFistDay()
    this.makeNext10WorkDays();
    this.postaviSveTermine();
    this.IzbaciZauzeteTermine();
  }

  convertSQLDateToDate(SQLDate:Date)
  {
    var dateStr=SQLDate.toString(); //returned from mysql timestamp/datetime field
    var a=dateStr.split("T");
    var d=a[0].split("-");
    var t=a[1].split(":");
    return new Date(dateStr);
  }

  brojNovih()
  {
    let cnt=0;
    if(this.myAppointments)
    {
      let now=new Date();
      for (let i=0;i<this.myAppointments.length;i++)
      {
        if(now<this.convertSQLDateToDate(this.myAppointments[i]["Time"]))
        {
          cnt++;
        }
      }
    }
    return cnt;
  }

  zakaziTermin(time:String,date:Date)
  {
    let res=time.split(":")
    date.setHours(parseInt(res[0]));
    date.setMinutes(parseInt(res[1]));
    date.setSeconds(0);
    date.setMilliseconds(0);

    let sqlDate=date.getFullYear() + '-' +
      ('00' + (date.getMonth()+1)).slice(-2) + '-' +
      ('00' + date.getDate()).slice(-2) + ' ' +
      ('00' + date.getHours()).slice(-2) + ':' +
      ('00' + date.getMinutes()).slice(-2) + ':' +
      ('00' + date.getSeconds()).slice(-2);

    const modalRef=this._modalService.open(NgbdModalConfirmTime);
    modalRef.componentInstance.termin=date.getDate().toString()+"."+(date.getMonth()+1).toString()+"."+date.getFullYear().toString()+". u "+time;
    modalRef.result.then((result) => {
      if ( result === 'confirm' ) {
         this.appointmentService.CreateWithTransaction(this.IDCustomer,sqlDate).subscribe(res=>{
           if(res!=null)
           {
            const modalRefReject=this._modalService.open(NgbdModalReject);
            modalRefReject.result.then((result) => {
              if ( result === 'confirm' ) {
                this.appointmentService.GetAllForByCodeOrderByDateDesc(this.Code).subscribe((appointments: Array<Appointment>)=>{
                  this.myAppointments=appointments;
                  this.cdr.detectChanges();
                });
                this.postaviSveTermine();
                this.IzbaciZauzeteTermine();
              }
            }, () => console.log('Exit modal'));
           }
           else
           {
            const modalRefAccept=this._modalService.open(NgbdModalAccept);
            modalRefAccept.result.then((result) => {
              if ( result === 'confirm' ) {
                this.appointmentService.GetAllForByCodeOrderByDateDesc(this.Code).subscribe((appointments: Array<Appointment>)=>{
                  this.myAppointments=appointments;
                  this.cdr.detectChanges();
                });
                this.postaviSveTermine();
                this.IzbaciZauzeteTermine();
              }
            }, () => console.log('Exit modal'));
           }
         })
      }
    }, () => console.log('Exit modal'));
  }

  noviTerminTitle(num:number)
  {
    let date=this.convertSQLDateToDate(this.myAppointments[num]["Time"]);
    return date.getDate().toString()+"."+(date.getMonth()+1).toString()+"."+date.getFullYear().toString()+". u "+date.getHours().toString()+":"+this.zeroPad(date.getMinutes(), 2);
  }

  prethodniTerminiTitle(){
    if(!this.myAppointments)
      return "";
    if(this.myAppointments.length==0)
      return "";
    let date=this.convertSQLDateToDate(this.myAppointments[0]["Time"]);
    return date.getDate().toString()+"."+(date.getMonth()+1).toString()+"."+date.getFullYear().toString()+".";
  }

  postaviSveTermine()
  {
    this.termini=new Array<Map<String,Boolean>>();
    for(let i=0;i<10;i++)
    {
      this.termini[this.termini.length]=new Map<String,Boolean>();
      if(this.next10[i].getDay()==6)
      {
        this.PostaviTermineSubota(this.termini[i]);
      }
      else
      {
        this.PostaviTermineRadniDan(this.termini[i]);
      }
    }

  }

  isSameDate(first:Date,second:Date)
  {
    return (first.getDate()==second.getDate()) && (first.getMonth()==second.getMonth())
  }

  IzbaciZauzeteTermine()
  {
    this.appointmentService.GetAllFromDate(this.date.getFullYear().toString()+"-"+(this.date.getMonth()+1).toString()+"-"+this.date.getDate().toString()).subscribe((appointments: Array<Appointment>)=>{
      this.nextAppoinments=appointments;
      for(let i=0;i<this.nextAppoinments.length;i++)
      {
        let date=this.convertSQLDateToDate(this.nextAppoinments[i]["Time"])
        for(let j=0;j<this.next10.length;j++)
        {
          if(this.isSameDate(this.next10[j],date))
          {
            let stringKey=date.getHours()+":"+this.zeroPad(date.getMinutes(), 2);
            this.termini[j].set(stringKey,false);
            if(this.nextAppoinments[i]["IDCustomer"]==-1)
            {
              let end=this.convertSQLDateToDate(this.nextAppoinments[i]["End"]);
              while(date<end)
              {
                let stringKey=date.getHours()+":"+this.zeroPad(date.getMinutes(), 2);
                this.termini[j].set(stringKey,false);
                date=new Date(date.getTime() + 30000);
              }
            }
            break;
          }
        }
      }
    });
  }

  zeroPad(num, places) {
    var zero = places - num.toString().length + 1;
    return Array(+(zero > 0 && zero)).join("0") + num;
  }

  PostaviTermineRadniDan(mapaTermina:Map<String,Boolean>)
  {
    mapaTermina.set("10:00",true);
    mapaTermina.set("10:30",true);
    mapaTermina.set("11:00",true);
    mapaTermina.set("11:30",true);
    mapaTermina.set("12:00",true);
    mapaTermina.set("12:30",true);
    mapaTermina.set("13:00",true);
    mapaTermina.set("13:30",true);
    mapaTermina.set("14:00",true);
    mapaTermina.set("14:30",true);
    mapaTermina.set("17:00",true);
    mapaTermina.set("17:30",true);
    mapaTermina.set("18:00",true);
    mapaTermina.set("18:30",true);
    mapaTermina.set("19:00",true);
    mapaTermina.set("19:30",true);

  }


  PostaviTermineSubota(mapaTermina:Map<String,Boolean>)
  {
    mapaTermina.set("10:00",true);
    mapaTermina.set("10:30",true);
    mapaTermina.set("11:00",true);
    mapaTermina.set("11:30",true);
    mapaTermina.set("12:00",true);
    mapaTermina.set("12:30",true);
    mapaTermina.set("13:00",true);
    mapaTermina.set("13:30",true);
  }


  changeFistDay()
  {
    while(true)
    {
      if(this.date.getDay()==0)
      {
        this.date.setDate(this.date.getDate()+1);
        if(this.date.getHours()>20)
        {
          this.date.setHours(0);
        }
        continue;
      }
      if(this.date.getHours()>20)
      {
        this.date.setDate(this.date.getDate()+1);
        this.date.setHours(0);
        continue;
      }
      return;
    }
  }
  getIdFromIndex(num:number)
  {
    return "toggle-"+(num+1).toString();
  }

  makeNext10WorkDays()
  {
    let i=new Date(this.date);
    let count=0;
    while (count<10)
    {
      if(i.getDay()!=0)
      {
        this.next10.push(new Date(i));
        count++;
      }
      i.setDate(i.getDate()+1);
    }
  }

  dayTitle(num:number)
  {
    return this.dani[this.next10[num].getDay()]+" "+this.next10[num].getDate()+"."+(this.next10[num].getMonth()+1).toString()+"."+this.next10[num].getFullYear()+"."
  }

  IzbaciTermine()
  {
    this.prikazaniTermini=true;
  }
  hasNoMoreTermins(num:number)
  {

    for (let [key, value] of this.termini[num]) {
      if(value) return false;
    }
    return true;
  }
}
