import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppointmentService {
  uri='https://app.nikolas-test.rs';
  //uri='http://localhost:3001';

  constructor(private http: HttpClient) { }

  GetAll(){
    return this.http.get(`${this.uri}/appointment/getAllAppoitments`);
  }

  GetAllWithName(){
    return this.http.get(`${this.uri}/appointment/getAllAppoitmentsWithName`);
  }


  GetAllForByCodeOrderByDateDesc(code:String){
    const data = {
      code:code
    };
    return this.http.post(`${this.uri}/appointment/getAllAppoitmentsWithCode`,data);
  }


  GetAllFromDate(date:String){
    const data = {
      date:date
    };
    return this.http.post(`${this.uri}/appointment/getAllAppoitmentsFromDate`,data);
  }

  Delete(id){
    const data = {
      id: id
    };
    return this.http.post(`${this.uri}/appointment/deleteAppoitment`,data);
  }

  Update(id,time){
    const data = {
      id: id,
      time:time
    };
    return this.http.post(`${this.uri}/appointment/updateAppoitment`,data);
  }

  Create(idCustomer,time){
    const data = {
      IDCustomer:idCustomer,
      time:time
    };
    return this.http.post(`${this.uri}/appointment/createAppoitment`,data);
  }

  CreateWithTransaction(idCustomer,time){
    const data = {
      IDCustomer:idCustomer,
      time:time
    };
    return this.http.post(`${this.uri}/appointment/createAppoitmentTransaction`,data);
  }

  CreateBreak(idCustomer,time,end){
    const data = {
      IDCustomer:idCustomer,
      time:time,
      end:end
    };
    return this.http.post(`${this.uri}/appointment/createBreakAppoitment`,data);
  }


}
