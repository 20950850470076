<mwl-demo-utils-calendar-header [(view)]="view" [(viewDate)]="viewDate">
</mwl-demo-utils-calendar-header>


    <alert [delete]="delete" [create]="create" (deleteEvent)="deleteMyEvent()" (createEvent)="createMyEvent()"></alert>



<div [ngSwitch]="view">
  <mwl-calendar-month-view
    *ngSwitchCase="'month'"
    [viewDate]="viewDate"
    [events]="events"
    (dayClicked)="changeDay($event.day.date)"
    [excludeDays]="excludeDays"
    [refresh]="refresh"
    (eventTimesChanged)="eventTimesChanged($event)"
    (eventClicked)="eventClicked($event)"
  >
  </mwl-calendar-month-view>
  <mwl-calendar-week-view
    *ngSwitchCase="'week'"
    [viewDate]="viewDate"
    [events]="events"    
    [dayStartHour]="9"
    [dayEndHour]="20"
    (dayHeaderClicked)="changeDay($event.day.date)"
    [excludeDays]="excludeDays"
    [refresh]="refresh"
    (eventTimesChanged)="eventTimesChanged($event)"
    (eventClicked)="eventClicked($event)"
    (hourSegmentClicked)="timeClicked($event)"
  >
  </mwl-calendar-week-view>
  <mwl-calendar-day-view
    *ngSwitchCase="'day'"
    [viewDate]="viewDate"
    [events]="events"
    [dayStartHour]="9"
    [dayEndHour]="20"
    [refresh]="refresh"
    [snapDraggedEvents]="false"
    (eventTimesChanged)="eventTimesChanged($event)"
    (eventClicked)="eventClicked($event)"
    (hourSegmentClicked)="timeClicked($event)"
  >
  </mwl-calendar-day-view>
</div>
