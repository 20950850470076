import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'ngbd-modal-confirm-autofocus',
    template: `
    <div class="modal-header">
      <h1 class="modal-title" id="modal-title">Brisanje termina</h1>
      <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p><strong>Da lis te sigurni da zelite da obisete termin <span class="text-primary">{{name}}</span>?</strong></p>
      <p>Obvestite musteriju o promeni termina.</p>
      <p>
      <span class="text-danger">Ova akcija se ne moze ponistiti.</span>
      </p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">Odustani</button>
      <button type="button" ngbAutofocus class="btn btn-danger" (click)="modal.close('confirm')">Obriši</button>
    </div>
    `
  })
  export class NgbdModalConfirmAutofocus {
    @Input() name;
    constructor(public modal: NgbActiveModal) {}
  }