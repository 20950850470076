<ng-container *ngIf="brojNovih()==0 && prethodniTerminiTitle()!=''">
    <p>&nbsp;&nbsp; Poštovani, Vas prethodni termin je bio <span class="text-primary">{{prethodniTerminiTitle()}}</span></p>
</ng-container>
<ng-container *ngIf="brojNovih()==1">
    <p>&nbsp;&nbsp; Poštovani, imate rezervisan termin: <span class="text-primary">{{noviTerminTitle(0)}}</span></p>
</ng-container>
<ng-container *ngIf="brojNovih()>1">
    <p>&nbsp;&nbsp; Poštovani, imate rezervisane termine: 
    <ng-container  *ngFor="let item of [].constructor(brojNovih()); let i = index">
        <br>&nbsp;&nbsp; <span class="text-primary">{{noviTerminTitle(i)}}</span>
    </ng-container></p>
</ng-container>

<ng-container *ngIf="prikazaniTermini==false">
    <button type="button" class="btn btn-primary" style="margin: 8px 8px 8px 8px;" (click)="IzbaciTermine()">Zakažite novi termin</button>
</ng-container>
<ng-container *ngIf="prikazaniTermini==true">
    <h2>&nbsp;&nbsp;Izaberite željeni termin</h2>
    <ngb-accordion #acc="ngbAccordion">
    <ng-container *ngFor="let day of next10; let i=index">
        <ngb-panel id="{{getIdFromIndex(i)}}" title="{{dayTitle(i)}}">
            <ng-template ngbPanelContent style="font-size:small;">
                <ng-container *ngIf="hasNoMoreTermins(i)==true">
                    <p>Nažalost, za ovaj dan nema više slobodnih termina.</p>
                </ng-container>
                <ng-container *ngFor="let vreme of termini[i].keys()">
                    <ng-container *ngIf="termini[i].get(vreme)==true">
                        <button class="btn btn-primary malo" (click)="zakaziTermin(vreme,day)">{{vreme}}</button>
                    </ng-container>
                </ng-container>
            </ng-template>
        </ngb-panel>
    </ng-container>
    
</ngb-accordion>

</ng-container>
