import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbDateStruct, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.css']
})
export class ConfigComponent implements OnInit {
  RDtimeBegin = {hour: 10, minute: 0};
  RDtimeEnd = {hour: 20, minute: 0};
  RDbreakTimeBegin = {hour: 15, minute: 0};
  RDbreakTimeEnd = {hour: 17, minute: 0};
  StimeBegin = {hour: 10, minute: 0};
  StimeEnd = {hour: 16, minute: 0};
  NtimeBegin = {hour: 10, minute: 0};
  NtimeEnd = {hour: 10, minute: 0};
  subota=true;
  nedelja=false;
  controlSubota:FormControl=new FormControl();
  controlNedelja:FormControl= new FormControl();
  model;

  
  constructor() {
   }

  ngOnInit(): void {
  }


}
