import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'ngbd-modal-confirm-time',
    template: `
    <div class="modal-header">
      <h1 class="modal-title" id="modal-title">Zakazivanje termina</h1>
      <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p><strong>Da li ste sigurni da želite da zakažete termin <span class="text-primary">{{termin}}</span>?</strong></p>
      
      <p>
      <span class="text-danger">Ova akcija se ne može poništiti.</span>
      </p>
      <p>O naknadnoj promeni termina, javite se frizeru.</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">Odustani</button>
      <button type="button" ngbAutofocus class="btn btn-primary" (click)="modal.close('confirm')">Zakaži</button>
    </div>
    `
  })
  export class NgbdModalConfirmTime {
    @Input() termin:String;
    constructor(public modal: NgbActiveModal) {}
  }