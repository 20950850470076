<button type="button" class="btn btn-primary" style="margin: 8px 8px 8px 8px;" (click)="createMusterija()">Dodaj novu musteriju</button>

<form>
  <div class="form-group form-inline">
      &nbsp; &nbsp; Pretraga: <input class="form-control ml-2" type="text" [formControl]="filter"/>
  </div>
</form>

<table class="table table-striped">
  <thead>
  <tr>
    <th scope="col"></th>
    <th scope="col">Ime</th>
    <th scope="col">Telefon</th>
    <th scope="col"></th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let musterija of musterije$| async; index as i">
    <th scope="row">
        <button style="background-color:#ffffff00; border-color:#ffffff00;" (click)="SeeProfile(musterija)"><i class="fas fa-eye"></i></button>
    </th>
    
    <td><ngb-highlight [result]="musterija.name" [term]="filter.value"></ngb-highlight></td>
    <td>{{musterija.phone}}</td>
    <td><a href="{{'tel:'+musterija.phone}}"><i class="fa fa-phone" aria-hidden="true"></i></a></td>
    
  </tr>
  </tbody>
</table>

