<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarNav">
    <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
      <li class="nav-item active">
        <a class="nav-link" href="#top" (click)="open('calendar')" data-toggle="collapse" data-target=".navbar-collapse.show">Kalendar <span class="sr-only">(current)</span></a>
      </li>      
      <li class="nav-item">
        <a class="nav-link"  href="#top"  (click)="open('customers')" data-toggle="collapse" data-target=".navbar-collapse.show">Musterije</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#top"  (click)="open('config')" data-toggle="collapse" data-target=".navbar-collapse.show">Konfiguracija</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#top"  (click)="open('test-customer')" data-toggle="collapse" data-target=".navbar-collapse.show">Test musterija</a>
      </li>
    </ul>
  </div>
</nav>


<ng-template #container>
</ng-template>