<!--
<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
    <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
      <li class="nav-item active">
        <a class="nav-link" href="/admin">Kalendar <span class="sr-only">(current)</span></a>
      </li>      
      <li class="nav-item">
        <a class="nav-link" href="/customers">Musterije</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/config">Konfiguracija</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/testCustomer">Test musterija</a>
      </li>
    </ul>
  </div>
</nav>
-->
<router-outlet></router-outlet>