import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  uri='https://app.nikolas-test.rs';
  //uri='http://localhost:3001';

  constructor(private http: HttpClient) { }

  GetAll(){
    return this.http.get(`${this.uri}/customer/getAllCustomers`);
  }

  GetByID(id){
    return this.http.get(`${this.uri}/customer/getCustomerByID/`+id);
  }


  GetByCode(code){
    return this.http.get(`${this.uri}/customer/getCustomerByCode/`+code);
  }

  Delete(id){
    const data = {
      id: id
    };
    return this.http.post(`${this.uri}/customer/deleteCustomer`,data);
  }

  async asyncDelete(id){
    const data = {
      id: id
    };
    return await this.http.post(`${this.uri}/customer/deleteCustomer`,data).toPromise();
  }

  DeleteByGet(id){
    return this.http.get(`${this.uri}/customer/deleteByID`+id);
  }

  Update(id,name,phone){
    const data = {
      id: id,
      name:name,
      phone:phone
    };
    return this.http.post(`${this.uri}/customer/updateCustomer`,data);
  }

  async asyncUpdate(id,name,phone){
    const data = {
      id: id,
      name:name,
      phone:phone
    };
    return await this.http.post(`${this.uri}/customer/updateCustomer`,data).toPromise();
  }

  Create(name,phone){
    const data = {
      name:name,
      phone:phone
    };
    return this.http.post(`${this.uri}/customer/createCustomer`,data);
  }

  async asyncCreate(name,phone)
  {
    const data = {
      name:name,
      phone:phone
    };
    return await this.http.post(`${this.uri}/customer/createCustomer`,data).toPromise();
  }


}
