import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'ngbd-modal-confirm-time',
    template: `
    <div class="modal-header">
      <h1 class="modal-title" id="modal-title">Zakazan termin</h1>
      <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.close('confirm')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p><strong><span class="text-primary">Uspešno ste zakazali termin.</span></strong></p>
    </div>
    <div class="modal-footer">
      <button type="button" ngbAutofocus class="btn btn-primary" (click)="modal.close('confirm')">OK</button>
    </div>
    `
  })
  export class NgbdModalAccept {
    constructor(public modal: NgbActiveModal) {}
  }