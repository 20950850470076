import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomerService } from 'src/app/service/customer.service';

@Component({
  selector: 'app-modal-profile-new',
  templateUrl: './modal-profile-new.component.html',
  styleUrls: ['./modal-profile-new.component.css']
})
export class ModalProfileNewComponent implements OnInit {

  name = new FormControl('');
  telefon = new FormControl('');

  constructor(public modal: NgbActiveModal, private customerService:CustomerService) { }

  ngOnInit(): void {
  }

  async sacuvaj()
  {
    if(this.name.value=='' || this.name.value==undefined || this.telefon.value=='' || this.telefon.value==undefined)
      return;
    await this.customerService.asyncCreate(this.name.value, this.telefon.value);
    this.modal.close('change');
  }

}
