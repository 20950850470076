import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Customer } from 'src/app/model/customer.model';
import { CustomerService } from 'src/app/service/customer.service';

@Component({
  selector: 'app-modal-profile',
  templateUrl: './modal-profile.component.html',
  styleUrls: ['./modal-profile.component.css']
})
export class ModalProfileComponent implements OnInit {

  @Input() musterija:Customer;

  @Input() name = new FormControl('');
  @Input() telefon = new FormControl('');

  constructor(public modal: NgbActiveModal, private customerService:CustomerService) { }

  ngOnInit(): void {
  }

  async izbrisi(){
      await this.customerService.asyncDelete(this.musterija.ID);
      this.modal.close('change');
  }

  async sacuvaj()
  {
      await this.customerService.asyncUpdate(this.musterija.ID, this.name.value, this.telefon.value);
      this.modal.close('change');
  }

  kopirajLink()
  {
    const val="https://www.locadelacabeza.nikolas-test.rs/client/"+this.musterija.personalLink;
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

}
