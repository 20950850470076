import { DecimalPipe } from '@angular/common';
import { Component, OnInit, PipeTransform } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Customer } from 'src/app/model/customer.model';
import { CustomerService } from 'src/app/service/customer.service';
import { ModalProfileNewComponent } from 'src/app/util/modal-profile-new/modal-profile-new.component';
import { ModalProfileComponent } from 'src/app/util/modal-profile/modal-profile.component';
import { ChangeDetectorRef } from '@angular/core';






@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.css']
})
export class CustomerComponent implements OnInit {
  options = {
    autoClose: false,
    keepAfterRouteChange: false
};

  musterije$: Observable<Customer[]>;
  filter = new FormControl('');

  musterije:Array<Customer>;


  constructor(private pipe: DecimalPipe, private _modalService:NgbModal, private customerService:CustomerService, private cdr: ChangeDetectorRef) {
    this.musterije$ = this.filter.valueChanges.pipe(
      startWith(''),
      map((text:string) => this.search(text, pipe))
    );
   }

  ngOnInit(): void {
    this.customerService.GetAll().subscribe((customers: Array<Customer>)=>{
      this.musterije=customers; 
      this.musterije$ = this.filter.valueChanges.pipe(
        startWith(''),
        map((text:string) => this.search(text, this.pipe))
      );
    });
    
  }

  SeeProfile(musterija:Customer)
  {
    const modalRef=this._modalService.open(ModalProfileComponent);
    modalRef.componentInstance.musterija=musterija;
    modalRef.componentInstance.name.value=musterija.name;
    modalRef.componentInstance.telefon.value=musterija.phone;
    modalRef.result.then((result)=>{
      if(result=='change')
      {
        this.customerService.GetAll().subscribe((customers: Array<Customer>)=>{
          this.musterije=customers; 
          this.musterije$ = this.filter.valueChanges.pipe(
            startWith(''),
            map((text:string) => this.search(text, this.pipe))
          );
          this.cdr.detectChanges();
        });
      }
    }, () => console.log('Exit modal'))
  }

  createMusterija()
  {
    const modalRef=this._modalService.open(ModalProfileNewComponent);
    modalRef.result.then((result)=>{
      if(result=='change')
      {
        this.customerService.GetAll().subscribe((customers: Array<Customer>)=>{
          this.musterije=customers; 
          this.musterije$ = this.filter.valueChanges.pipe(
            startWith(''),
            map((text:string) => this.search(text, this.pipe))
          );
          this.cdr.detectChanges();
        });
      }
    }, () => console.log('Exit modal'))
  }

  search(text: string, pipe: PipeTransform): Customer[] {
    if(this.musterije==undefined)
      return [];
    return this.musterije.filter(musterija => {
      const term = text.toLowerCase();
      return musterija.name.toLowerCase().includes(term);
    });
  }

}
