import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminAllInOneComponent } from './pages/admin-all-in-one/admin-all-in-one.component';
import { AdminComponent } from './pages/admin/admin.component';
import { ClientComponent } from './pages/client/client.component';
import { ConfigComponent } from './pages/config/config.component';
import { CustomerComponent } from './pages/customer/customer.component';
import { Page404Component } from './pages/page404/page404.component';
import { TestCustomerComponent } from './pages/test-customer/test-customer.component';


const routes: Routes = [
  { path: 'client/:id',             component: ClientComponent },
  { path: '404',             component: Page404Component},
  { path: 'allinone',             component: AdminAllInOneComponent},
  { path: '**', redirectTo: '404'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
