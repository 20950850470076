<h1>Konfiguracija</h1>
<h2>Radni dani</h2>
<table>
    <tr>
        <td>
            <h3 style="padding-top: 18px;">Radno vreme od: </h3>
        </td>
        <td width="20%">
        </td>
        <td>
            <ngb-timepicker [(ngModel)]="RDtimeBegin"  [hourStep]=1 [minuteStep]=30 [ngModelOptions]="{standalone: true}"></ngb-timepicker>
        </td>
    </tr>
    <tr>
        <td>
            <h3 style="padding-top: 18px;">Radno vreme do: </h3>
        </td>
        <td width="20%">
        </td>
        <td>
            <ngb-timepicker [(ngModel)]="RDtimeEnd"  [hourStep]=1 [minuteStep]=30 [ngModelOptions]="{standalone: true}"></ngb-timepicker>
        </td>
    </tr>
    <tr>
        <td>
            <h3 style="padding-top: 18px;">Pauza od: </h3>
        </td>
        <td width="20%">
        </td>
        <td>
            <ngb-timepicker [(ngModel)]="RDbreakTimeBegin"  [hourStep]=1 [minuteStep]=30 [ngModelOptions]="{standalone: true}"></ngb-timepicker>
        </td>
    </tr>
    <tr>
        <td>
            <h3 style="padding-top: 18px;">Pauza do: </h3>
        </td>
        <td width="20%">
        </td>
        <td>
            <ngb-timepicker [(ngModel)]="RDbreakTimeEnd"  [hourStep]=1 [minuteStep]=30 [ngModelOptions]="{standalone: true}"></ngb-timepicker>
        </td>
    </tr>
</table>

<h2><input
  type="checkbox"
  [checked]="subota"
  (change)="subota = !subota"/>&nbsp;Subota</h2>
<ng-container *ngIf="subota">
    <table>
        <tr>
            <td>
                <h3 style="padding-top: 18px;">Radno vreme od: </h3>
            </td>
            <td width="20%">
            </td>
            <td>
                <ngb-timepicker [(ngModel)]="StimeBegin"  [hourStep]=1 [minuteStep]=30 [ngModelOptions]="{standalone: true}"></ngb-timepicker>
            </td>
        </tr>
        <tr>
            <td>
                <h3 style="padding-top: 18px;">Radno vreme do: </h3>
            </td>
            <td width="20%">
            </td>
            <td>
                <ngb-timepicker [(ngModel)]="StimeEnd"  [hourStep]=1 [minuteStep]=30 [ngModelOptions]="{standalone: true}"></ngb-timepicker>
            </td>
        </tr>
    </table>
    
</ng-container>
<h2><input
  type="checkbox"
  [checked]="nedelja"
  (change)="nedelja = !nedelja"/>&nbsp;Nedelja</h2>
<ng-container *ngIf="nedelja">
    <table>
        <tr>
            <td>
                <h3 style="padding-top: 18px;">Radno vreme od: </h3>
            </td>
            <td width="20%">
            </td>
            <td>
                <ngb-timepicker [(ngModel)]="NtimeBegin"  [hourStep]=1 [minuteStep]=30 [ngModelOptions]="{standalone: true}"></ngb-timepicker>
            </td>
        </tr>
        <tr>
            <td>
                <h3 style="padding-top: 18px;">Radno vreme do: </h3>
            </td>
            <td width="20%">
            </td>
            <td>
                <ngb-timepicker [(ngModel)]="NtimeEnd"  [hourStep]=1 [minuteStep]=30 [ngModelOptions]="{standalone: true}"></ngb-timepicker>
            </td>
        </tr>
    </table>
    
</ng-container>

<button type="button" class="btn btn-primary">Sačuvaj izmene</button>
<br/><br/>
<h2>Slobodni dani</h2>
<p>Izaberite datum i postavite ga kao neradan dan.</p>
<form class="form-inline">
  <div class="form-group">
    <div class="input-group">
      <input class="form-control" placeholder="yyyy-mm-dd"
             name="dp" [(ngModel)]="model" ngbDatepicker #d="ngbDatepicker">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"><i class="fa fa-calendar" aria-hidden="true"></i></button>
      </div>
    </div>
  </div>
</form>
<button type="button" class="btn btn-primary">Dodaj slobodan dan</button>
