<div *ngFor="let alert of alerts" class="{{cssClass(alert)}}">
    <ng-container *ngIf="delete===true">
        <button class="btn btn-info m-1" style="background-color:#ffffff00; border-color:#ffffff00;" (click)="doDelete()"><i class="fas fa-fw fa-trash-alt" style="color:red;font-size: 1.4rem;"></i></button>
    </ng-container>
    <ng-container *ngIf="create===true">
        <button class="btn btn-warning m-1" style="background-color:#ffffff00; border-color:#ffffff00;" (click)="doCreate()"><i class="fas fa-fw fa-pencil-alt" style="color:black;font-size: 1.4rem;"></i></button>
    </ng-container>
    
    <a class="close" (click)="removeAlert(alert)">&times;</a>
    <span [innerHTML]="alert.message"></span>
    
</div>