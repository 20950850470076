import { formatDate } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';

import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { colors } from 'src/app/demo-utils/colors';
import { Customer } from 'src/app/model/customer.model';
import { CustomerService } from 'src/app/service/customer.service';

@Component({
  selector: 'app-modal-create-event',
  templateUrl: './modal-create-event.component.html',
  styleUrls: ['./modal-create-event.component.css']
})
export class ModalCreateEventComponent implements OnInit {
  @Input() eventStartTime:Date;
  @Input() time={hour: 13, minute: 30};
  musterije: string[] = [];
  customers:Array<Customer>;

  filteredMusterije: Observable<string[]>;
  control = new FormControl();

  isTermin:boolean=true;

  constructor(public modal: NgbActiveModal,private customerService:CustomerService) { }

  ngOnInit(): void {
    this.customerService.GetAll().subscribe((customers: Array<Customer>)=>{
      this.customers=customers; 
      this.makeMusterijeFromCustomers();
      this.filteredMusterije = this.control.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value))
      );
    });
    
  }

  makeMusterijeFromCustomers()
  {
    for(let i=0;i<this.customers.length;i++)
    {
      this.musterije.push(this.customers[i].name.toString());
    }
  }

  private _filter(value: string): string[] {
    const filterValue = this._normalizeValue(value);
    return this.musterije.filter(musterije => this._normalizeValue(musterije).includes(filterValue));
  }

  private _normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }

  setTermin(isTermin:boolean)
  {
    this.isTermin=isTermin;
  }

  formatDate(date:Date)
  {
    return formatDate(date, 'h:mm a','en-us')
  }

  getIDFromCustomer()
  {
    for(let i=0;i<this.customers.length;i++)
    {
      if(this.customers[i].name==this.control.value)
        return this.customers[i].ID;
    }
    return -1;
  }

  sacuvaj()
  {
    let res;
    if(this.isTermin)
    { 
      res={
        title: this.control.value,
        color: colors.blue,
        start: this.eventStartTime,
        draggable: true,
        meta:  this.getIDFromCustomer()   
      }
    }
    else{
      let endDate=new Date(this.eventStartTime.getFullYear(),this.eventStartTime.getMonth(),this.eventStartTime.getDate(),this.time.hour,this.time.minute);
      res={
        title: "Pauza",
        color: colors.red,
        start: this.eventStartTime,
        end: endDate,
        draggable: true,
        meta:-1     
      }

      
    }
    this.modal.close(res);
  }

}
