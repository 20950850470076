<div class="modal-header">
      <h1 class="modal-title" id="modal-title">Dodavanje termina</h1>
      <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
</div>

<div class="modal-body">
    <button type="button" [className]="isTermin!=true ? 'btn btn-outline-info btn-round' : 'btn btn-info btn-round'" (click)="setTermin(true)">MUŠTERIJA</button>
    &nbsp; &nbsp;
    <button type="button" [className]="isTermin!=false ? 'btn btn-outline-danger btn-round':'btn btn-danger btn-round'" (click)="setTermin(false)">PAUZA</button>
    <br/>
    <br/>
    <p><strong>Početak termina <span class="text-primary">{{formatDate(eventStartTime)}}</span></strong></p>
    <ng-container *ngIf="isTermin">
        <form class="example-form">
            <input type="text"
                    placeholder="Pronadji musteriju"
                    [formControl]="control"
                    [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let street of filteredMusterije | async" [value]="street">
                {{street}}
                </mat-option>
            </mat-autocomplete>
        </form>
    </ng-container>
    <ng-container *ngIf="!isTermin">
        <form class="example-form">
            <p><strong>Pauza traje do: </strong></p>
            <ngb-timepicker [(ngModel)]="time"  [hourStep]=1 [minuteStep]=30 [ngModelOptions]="{standalone: true}"></ngb-timepicker>
        </form>
    </ng-container>
    
</div>



<div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">Odustani</button>
      <button type="button" ngbAutofocus class="btn btn-danger" (click)="sacuvaj()">Sačuvaj</button>
</div>