import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { AdminComponent } from './pages/admin/admin.component';
import { CustomerComponent } from './pages/customer/customer.component';
import { ConfigComponent } from './pages/config/config.component';
import { DemoUtilsModule } from './demo-utils/module';
import { AlertModule } from './_alert';
import { ModalCreateEventComponent } from './util/modal-create-event/modal-create-event.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {NgbDatepickerModule, NgbTimepickerModule, NgbDate, NgbModule,NgbModal} from '@ng-bootstrap/ng-bootstrap'
import { CommonModule, DecimalPipe } from '@angular/common';
import { ModalProfileComponent } from './util/modal-profile/modal-profile.component';
import { ModalProfileNewComponent } from './util/modal-profile-new/modal-profile-new.component';
import { TestCustomerComponent } from './pages/test-customer/test-customer.component';
import { AdminAllInOneComponent } from './pages/admin-all-in-one/admin-all-in-one.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ClientComponent } from './pages/client/client.component';
import { Page404Component } from './pages/page404/page404.component';

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    CustomerComponent,
    ConfigComponent,
    ModalCreateEventComponent,
    ModalProfileComponent,
    ModalProfileNewComponent,
    TestCustomerComponent,
    AdminAllInOneComponent,
    ClientComponent,
    Page404Component
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
    DemoUtilsModule,
    AlertModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgbTimepickerModule,
    NgbDatepickerModule,

    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    NgbModule,
  ],
  providers: [DecimalPipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
