import { ChangeDetectionStrategy, Component, OnInit,LOCALE_ID, Injectable, ChangeDetectorRef} from '@angular/core';
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,
} from 'date-fns';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarEventTitleFormatter,
  CalendarView,
} from 'angular-calendar';
import { colors } from 'src/app/demo-utils/colors';
import { AlertService } from 'src/app/_alert';
import { formatDate } from '@angular/common';
import { NgbdModalConfirmAutofocus } from 'src/app/util/modal-focus';
import { ModalCreateEventComponent } from 'src/app/util/modal-create-event/modal-create-event.component';
import { CustomEventTitleFormatter } from './custom-event-title-formatter';
import { Appointment } from 'src/app/model/appointment.model';
import { AppointmentService } from 'src/app/service/appointment.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./admin.component.css'],
  providers: [
    {
      provide: CalendarEventTitleFormatter,
      useClass: CustomEventTitleFormatter,
    },
  ],
})
export class AdminComponent implements OnInit {
  selectedEvent:CalendarEvent;
  selectedTime:Date;


  constructor(private alertService: AlertService,private _modalService: NgbModal,private cdr: ChangeDetectorRef, private appointmentService:AppointmentService) { }

  ngOnInit(): void {
    this.loadAppointments();

  }

  loadAppointments(){
    this.appointmentService.GetAllWithName().subscribe((appointments: Array<Appointment>)=>{
      this.appointments=appointments;
      this.makeEventsFromAppointments();
      this.cdr.detectChanges();
    });
  }

  makeEventsFromAppointments()
  {
    this.events=[];
    for(let i=0; i<this.appointments.length;i++)
    {
      let appointment=this.appointments[i];
      var object:CalendarEvent={
        title: appointment["name"],
        start: this.convertSQLDateToDate(appointment["Time"]),
        meta:appointment.ID
      }
      if(appointment["name"]=="Pauza")
      {
        object["color"]=colors.red;
        object.draggable=false;
        object.end=this.convertSQLDateToDate(appointment["End"])
      }
      else
      {
        object["color"]=colors.blue;
        object["draggable"]=true;
      }
      this.events.push(object);
    }
  }

  convertSQLDateToDate(SQLDate:Date)
  {
    var dateStr=SQLDate.toString(); //returned from mysql timestamp/datetime field
    var a=dateStr.split("T");
    var d=a[0].split("-");
    var t=a[1].split(":");
    //
    //return new Date(parseInt(d[0]),(parseInt(d[1])-1),parseInt(d[2]),parseInt(t[0])+2,parseInt(t[1]),parseInt(t[2]));
    return new Date(dateStr);
  }

  view: CalendarView = CalendarView.Week;

  viewDate: Date = new Date();

  delete:boolean=false;
  create:boolean=false;

  /*events: CalendarEvent[] = [
    {
      title: 'Sajinov drugar Pera',
      color: colors.blue,
      start: new Date(2020,11,14,13,30),
      draggable: true,
    },
    {
      title: 'Sajin',
      color: colors.blue,
      start: new Date(2020,11,14,10,30),
      draggable: true,
    },
    {
      title: 'Pauza',
      color: colors.red,
      start: new Date(2020,11,14,11,0),
      end: new Date(2020,11,14,12,0)

    },
    {
      title: 'Somi',
      color: colors.blue,
      start: new Date(2020,11,14,12,30),
      end: new Date(2020,11,14,13,0),
      draggable: true,

    }
  ];*/
  events: CalendarEvent[];
  appointments:Array<Appointment>;

  changeDay(date: Date) {
    this.viewDate = date;
    this.view = CalendarView.Day;
  }

  excludeDays: number[] = [0];

  refresh: Subject<any> = new Subject();

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    event.start = newStart;
    event.end = newEnd;
    this.refresh.next();

    //change in database
    let sqlDate=event.start.getFullYear() + '-' +
      ('00' + (event.start.getMonth()+1)).slice(-2) + '-' +
      ('00' + event.start.getDate()).slice(-2) + ' ' +
      ('00' + event.start.getHours()).slice(-2) + ':' +
      ('00' + event.start.getMinutes()).slice(-2) + ':' +
      ('00' + event.start.getSeconds()).slice(-2);
    this.appointmentService.Update(event.meta,sqlDate).subscribe();
  }

  eventClicked(event)
  {
    this.alertService.clear();
    this.alertService.info(formatDate(event.event.start, 'h:mm a','en-us')+" "+event.event.title);
    this.delete=true;
    this.create=false;
    this.selectedEvent=event.event;
  }

  timeClicked(event)
  {
    this.alertService.clear();
    this.alertService.warn("Prazan termin u "+formatDate(event.date, 'h:mm a','en-us'));
    this.create=true;
    this.delete=false;
    this.selectedTime=event.date;
  }

  deleteMyEvent()
  {
    const modalRef=this._modalService.open(NgbdModalConfirmAutofocus);
    modalRef.componentInstance.name=formatDate(this.selectedEvent.start, 'h:mm a','en-us')+" "+this.selectedEvent.title;
    modalRef.result.then((result) => {
      if ( result === 'confirm' ) {
         this.deleteEventFromCalendarAndDatabase(this.selectedEvent);
         this.alertService.clear();
         this.cdr.detectChanges();
      }
    }, () => console.log('Exit modal'));
  }

  deleteEventFromCalendarAndDatabase(event:CalendarEvent)
  {
    // delete from view
    this.events = this.events.filter((iEvent) => iEvent !== event);

    //delete from database
    this.appointmentService.Delete(event.meta).subscribe();
  }

  createMyEvent()
  {

    const modalRef=this._modalService.open(ModalCreateEventComponent);
    modalRef.componentInstance.eventStartTime=this.selectedTime;
    modalRef.componentInstance.time.hour=this.selectedTime.getHours();
    modalRef.componentInstance.time.minute=this.selectedTime.getMinutes();
    modalRef.result.then((result)=>{
      //create in view
      this.events.push(result);
      this.alertService.clear();
      this.events=[].concat(this.events);
      this.cdr.detectChanges();

      //create in database
      let sqlDate=this.selectedTime.getFullYear() + '-' +
      ('00' + (this.selectedTime.getMonth()+1)).slice(-2) + '-' +
      ('00' + this.selectedTime.getDate()).slice(-2) + ' ' +
      ('00' + this.selectedTime.getHours()).slice(-2) + ':' +
      ('00' + this.selectedTime.getMinutes()).slice(-2) + ':' +
      ('00' + this.selectedTime.getSeconds()).slice(-2);

      if(result["meta"]==-1)
      {
        let sqlDate2=result.end.getFullYear() + '-' +
          ('00' + (result.end.getMonth()+1)).slice(-2) + '-' +
          ('00' + result.end.getDate()).slice(-2) + ' ' +
          ('00' + result.end.getHours()).slice(-2) + ':' +
          ('00' + result.end.getMinutes()).slice(-2) + ':' +
          ('00' + result.end.getSeconds()).slice(-2);
          this.appointmentService.CreateBreak(parseInt(result["meta"]),sqlDate,sqlDate2).subscribe();

      }
      else
      {
        this.appointmentService.Create(parseInt(result["meta"]),sqlDate).subscribe();
      }



    }, () => console.log('Exit modal'));


  }
}
