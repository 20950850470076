import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { AdminComponent } from '../admin/admin.component';
import { ConfigComponent } from '../config/config.component';
import { CustomerComponent } from '../customer/customer.component';
import { TestCustomerComponent } from '../test-customer/test-customer.component';

@Component({
  selector: 'app-admin-all-in-one',
  templateUrl: './admin-all-in-one.component.html',
  styleUrls: ['./admin-all-in-one.component.css']
})
export class AdminAllInOneComponent implements OnInit {

  @ViewChild('container', { static: true, read: ViewContainerRef }) entry: ViewContainerRef;

  constructor(private resolver: ComponentFactoryResolver) { }

  ngOnInit()
  {
    this.entry.clear();
    const factory = this.resolver.resolveComponentFactory(AdminComponent);
    const componentRef = this.entry.createComponent(factory);
  }

  open(name: string)
  {
    this.entry.clear();
    switch(name) {
      case "calendar":
        const factory = this.resolver.resolveComponentFactory(AdminComponent);
        const componentRef = this.entry.createComponent(factory);
        break;
      case "customers":
        const factory2 = this.resolver.resolveComponentFactory(CustomerComponent);
        const componentRef2 = this.entry.createComponent(factory2);
        break;
      case "config":
        const factory3 = this.resolver.resolveComponentFactory(ConfigComponent);
        const componentRef3 = this.entry.createComponent(factory3);
        break;
      case "test-customer":
        const factory4 = this.resolver.resolveComponentFactory(TestCustomerComponent);
        const componentRef4 = this.entry.createComponent(factory4);
        break;
      default:
        
    }
  }
  
  createComponentMap()
  {
  }

}
